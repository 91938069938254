import css from 'styled-jsx/css';

export const colors = {
    brandRed: 'hsl(4, 90%, 58%)',
    backgroundGrey: 'hsl(4, 20%, 96%)',
    secondary: '#2074d9'
};

export default css.global`
    body {
        margin: 0;
        font-family: Lato, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #263238;
        font-size: 16px;
        line-height: 24px;
    }

    h1,
    h2,
    h3,
    h4 {
        text-transform: uppercase;
        letter-spacing: 0.05em;
    }

    h1 {
        font-size: 36px;
        line-height: 48px;
        margin: 0;
    }

    h2,
    h3 {
        margin: 40px 0 0;
    }

    h2 {
        font-size: 28px;
        line-height: 40px;
    }

    h3 {
        font-size: 14px;
        line-height: 24px;
    }

    p,
    ul,
    ol {
        margin: 8px 0;
    }

    ul,
    ol {
        padding-left: 24px;
    }

    p:first-child,
    ul:first-child,
    ol:first-child {
        margin-top: 8px;
    }

    p,
    q,
    li {
        color: rgba(38, 50, 56, 0.8);
        font-size: 16px;
        line-height: 24px;
        max-width: 680px;
    }

    p a,
    q a,
    li a {
        color: ${colors.brandRed};
        position: relative;
        text-decoration: none;
    }

    @media (hover: hover) {
        p a::after,
        q a::after,
        li a::after,
        label a::after,
        .testimonials .tabs a::after {
            content: '';
            display: inline-block;
            width: 0;
            height: 1px;
            background: ${colors.brandRed};
            position: absolute;
            left: 0px;
            bottom: 0px;
            transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
            transform: translateZ(0);
        }

        p a:hover::after,
        q a:hover::after,
        li a:hover::after,
        label a:hover::after,
        .testimonials .tabs a:hover::after {
            width: 100%;
        }
    }

    @media (hover: none) {
        p a::after,
        q a::after,
        li a::after,
        label a::after,
        .testimonials .tabs a::after {
            width: 100%;
        }
    }

    p + p {
        margin-top: 16px;
    }

    a {
        transition: opacity 0.1s cubic-bezier(0.4, 0, 0.2, 1);
    }

    a.button,
    input[type='submit'],
    button.button {
        display: inline-block;
        background-color: ${colors.brandRed};
        border-width: 0;
        border-radius: 3px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.19), 0 3px 6px rgba(0, 0, 0, 0.23);
        text-transform: uppercase;
        padding: 4px 16px;
        color: #fff;
        font-size: 14px;
        font-family: Lato, sans-serif;
        line-height: 32px;
        font-weight: 700;
        letter-spacing: 0.05em;
        cursor: pointer;
        text-decoration: none;
        -webkit-appearance: none;
        transition: box-shadow 0.1s cubic-bezier(0.4, 0, 0.2, 1),
            transform 0.1s cubic-bezier(0.4, 0, 0.2, 1);
    }

    a.button:not(.light):active,
    input[type='submit']:not(.light):active,
    button.button:not(.light):active {
        background-color: hsl(4, 90%, 40%);
        color: hsl(4, 0%, 82%);
        transform: translateY(1px);
    }

    a.button.light,
    input[type='submit'].light,
    button.button.light {
        background-color: transparent;
        color: ${colors.brandRed};
        box-shadow: none;
        padding: 0;

        &.dark {
            color: #263238;
        }
    }
    
    button.button.secondary {
    
        background-color: ${colors.secondary};
        border-color: ${colors.secondary};
    }
    
    .button.light:disabled {
        cursor: not-allowed;
        color: #AAA;
        pointer-events: none;
    }

    a.button.outline,
    input[type='submit'].outline,
    button.button.outline {
        background-color: transparent;
        color: #fff;
        border: 1px solid #fff;
        box-shadow: none;
        padding: 3px 16px;

        &.dark {
            color: #263238;
            border: 1px solid #263238;
        }
    }

    a.button.disabled,
    input[type='submit'].disabled,
    button.button.disabled, 
    button.button:disabled {
        pointer-events: none;
        background: rgba(38, 50, 56, 0.2);
        color: rgba(38, 50, 56, 0.3);
        box-shadow: none;
    }

    a.button,
    button.button {
        margin-top: 8px;
    }

    button.reset {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }

    input[type='submit'] {
        margin-top: 24px;
    }

    label {
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.05em;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 24px;
        display: block;
        width: 100%;
        position: relative;
    }

    label .error-message,
    label + .error-message {
        color: ${colors.brandRed};
        line-height: 16px;
        font-size: 10px;
        position: absolute;
        bottom: -16px;
        margin: 0;
        letter-spacing: 0.05em;
        font-weight: 700;
        text-transform: uppercase;
    }

    textarea,
    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='password'],
    input[type='date'],
    div.StripeElement,
    select,
    input[type='range'],
    .wysiwyg-editor {
        width: 100%;
        margin: 8px 0 0;
        box-sizing: border-box;
        display: block;
    }

    textarea,
    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='password'],
    input[type='date'],
    div.StripeElement,
    select,
    .rc-input-number-input,
    .wysiwyg-editor {
        padding: 0 8px;
        box-shadow: none;
        font-size: 16px;
        font-family: Lato, sans-serif;
        letter-spacing: normal;
        line-height: 24px;
        border-radius: 3px;
        border: 1px solid rgba(38, 50, 56, 0.2);
        text-transform: none;
        background-color: #fff;
        -webkit-appearance: none;
    }

    input[type='range'] {
        -webkit-appearance: none;
        background: none;
        width: 100%;
    }
    input[type='range']:focus {
        outline: none;
    }

    input[type='range']::-webkit-slider-runnable-track {
        width: 100%;
        height: 6px;
        cursor: pointer;
        box-shadow: none;
        background: rgba(38, 50, 56, 0.1);
        border-radius: 3px;
        border-width: 0;
    }

    input[type='range']::-webkit-slider-thumb {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
        border-width: 0;
        height: 20px;
        width: 20px;
        border-radius: 10px;
        background: #fff;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -7px;
    }

    input[type='range']:focus::-webkit-slider-thumb {
        box-shadow: 0 0 3px #0199e5, 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
    }

    input[type='range']:focus::-webkit-slider-runnable-track {
        background: rgba(38, 50, 56, 0.1);
    }

    input[type='range']::-moz-range-track {
        width: 100%;
        height: 6px;
        cursor: pointer;
        box-shadow: none;
        background: rgba(38, 50, 56, 0.1);
        border-radius: 3px;
        border-width: 0;
    }

    input[type='range']::-moz-range-thumb {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
        border-width: 0;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: #fff;
        cursor: pointer;
    }

    input[type='range']:focus::-moz-range-thumb {
        box-shadow: 0 0 3px #0199e5, 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
    }

    input[type='range']::-ms-track {
        width: 100%;
        height: 6px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }

    input[type='range']::-ms-fill-lower {
        background: #7f8488;
        border-width: 0;
        border-radius: 6px;
        box-shadow: none;
    }

    input[type='range']::-ms-fill-upper {
        background: rgba(38, 50, 56, 0.1);
        border-width: 0;
        border-radius: 6px;
        box-shadow: none;
    }

    input[type='range']::-ms-thumb {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
        border: 0;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: #fff;
        cursor: pointer;
        height: 6px;
    }

    input[type='range']:focus::-ms-thumb {
        box-shadow: 0 0 3px #0199e5, 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
    }

    input[type='range']:focus::-ms-fill-lower {
        background: rgba(38, 50, 56, 0.1);
    }

    input[type='range']:focus::-ms-fill-upper {
        background: #ffffff;
    }

    label div.StripeElement {
        line-height: 24px;
        padding: 8px;
    }

    select,
    input[type='date'] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding: 0 36px 0 6px;
        text-overflow: ellipsis;
    }

    @-moz-document url-prefix() {
        select,
        select:-moz-focusring,
        select::-moz-focus-inner {
            color: transparent !important;
            text-shadow: 0 0 0 #000 !important;
        }

        select.placeholder {
            text-shadow: 0 0 0 rgba(38, 50, 56, 0.15) !important;
        }
    }

    select option:not([disabled]) {
        color: #263238;
    }

    textarea::placeholder,
    input[type='text']::placeholder,
    input[type='number']::placeholder,
    input[type='email']::placeholder,
    select.placeholder,
    .wysiwyg-editor {
        color: rgba(38, 50, 56, 0.8);
    }

    textarea,
    .wysiwyg-editor {
        min-height: 120px;
        resize: vertical;
        -webkit-appearance: none;
        padding: 8px;
    }

    textarea.invalid,
    input[type='text'].invalid,
    input[type='number'].invalid,
    input[type='email'].invalid,
    input[type='password'].invalid,
    div.StripeElement.StripeElement--invalid {
        border-color: ${colors.brandRed};
        color: ${colors.brandRed};
    }

    input[type='date'].invalid,
    select.invalid {
        border-color: ${colors.brandRed};
    }

    input:not([type='submit']),
    select,
    div.StripeElement {
        height: 40px;
        align-self: flex-end;
    }

    input[type='text']:focus,
    input[type='number']:focus,
    input[type='email']:focus,
    input[type='password']:focus,
    input[type='date']:focus,
    textarea:focus,
    select:focus,
    div.StripeElement.StripeElement--focus,
    .wysiwyg:focus {
        border: 1px solid #0199e5;
        outline: none;
    }

    input[readonly]:not(.flatpickr-input + input),
    input[readonly]:not(.flatpickr-input + input):focus,
    textarea[readonly] {
        background-color: hsl(200, 19%, 0%, 0.08);
        border-width: 0px;
    }

    svg.responsive,
    img.responsive {
        max-width: 100%;
    }

    video {
        width: 100% !important;
        height: auto !important;
    }

    .flex-vid {
        padding-bottom: 56.25%;
    }

    .flex-vid-4x3 {
        padding-bottom: 75%;
    }

    .flex-vid,
    .flex-vid-4x3 {
        position: relative;
        height: 0;

        & object,
        & embed,
        & iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    hr {
        border-top: 1px solid #0000001a;
        border-bottom: 0;
        margin: 24px 0;
    }

    hr.red {
        max-width: 100px;
        border: 4px solid ${colors.brandRed};
    }

    .no-shadow {
        box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0) !important;
    }
    .shadow-base {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    }
    .shadow-md {
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    }
    .shadow-lg {
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    }
    .shadow-xl {
        box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    }
    .shadow-2xl {
        box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    }

    .modal-button-container {
        text-align: right;

        & button {
            box-shadow: none;

            & + button {
                margin-left: 16px;
            }
        }
    }
    .rc-input-number-input {
        border: none;
        color: black;
        padding: 0px 8px;
    }
    div[data-slate-editor] {
        -webkit-user-modify: read-write !important;
    }
    .react-select__input {
        height: 20px !important;
    }
    .react-select__group-heading {
        background-color: #e5e4e2;
        padding-top: 10px;
        padding-bottom: 10px;
    }
`;
